<template>
  <div class="account-settings-info-right">
    <div class="account-settings-info-title">
      <span>{{selectedMenu.meta.title}}</span>
    </div>
    <a-row :gutter="16">
      <a-col span="12">
        <h3>Instance</h3>
        <a-row>
          <a-icon type="clock-circle"/>
          Start
          <span v-if="data">{{getFormatDate}}</span>
        </a-row>
        <a-divider type="horizontal"/>
        <h3>JVM</h3>
        <a-row>
          Runtime
          <span v-if="data">{{data.jvm.name + data.jvm.version}}</span>
        </a-row>
        <a-row>
          processors
          <span v-if="data">{{data.jvm.processors}}</span>
        </a-row>
      </a-col>
      <a-col span="12">
        <h3>
          <a-icon type="fund" theme="twoTone" twoToneColor="#52c41a"/>
          JVM-Memory
        </h3>
        <a-row>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import SERVICE_URLS from '../../../api/service.url'
  import { humanReadableUnits } from '../../../utils/utils'
  import menuTile from '../../common/mixins/menuTitle'

  export default {
    name: 'JVM',
    mixins: [menuTile],
    data() {
      return {
        data: null,
        setIntervalId: null,
        humanReadableUnits: humanReadableUnits
      }
    },
    computed: {
      getFormatDate() {
        const date = new Date(this.data.jvm.jmx.startTime)
        const seperator1 = '-'
        const seperator2 = ':'
        let month = date.getMonth() + 1
        let strDate = date.getDate()
        if (month >= 1 && month <= 9) {
          month = '0' + month
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = '0' + strDate
        }
        return date.getFullYear() + seperator1 + month + seperator1 + strDate
          + ' ' + date.getHours() + seperator2 + date.getMinutes()
          + seperator2 + date.getSeconds()
      },
      jvmMemoryUsed() {
        const used = this.data.jvm.memory.used
        return used.substring(0, used.indexOf('(') - 1)
      },
      jvmPercentage() {
        const max = this.data.jvm.memory.raw.max
        const total = this.data.jvm.memory.raw.total
        let num = total * 1.0 / max
        num = num.toFixed(4)
        return num * 100
      },
      jvmPercentageUsed() {
        const total = this.data.jvm.memory.raw.total
        const used = this.data.jvm.memory.raw.used
        let num = used * 1.0 / total * 100
        num = num.toFixed(2)
        return parseFloat(num)
      },
      systemPhysicalPercentage() {
        const max = this.data.system.totalPhysicalMemorySize
        const used = max - this.data.system.freePhysicalMemorySize
        let num = used / max * 100
        num = num.toFixed(2)
        return parseFloat(num)
      },
      totalPhysicalMemorySize() {
        const bytes = this.data.system.totalPhysicalMemorySize
        return this.humanReadableUnits(bytes)
      },
      usedPhysicalMemorySize() {
        const bytes = this.data.system.totalPhysicalMemorySize - this.data.system.freePhysicalMemorySize
        return this.humanReadableUnits(bytes)
      },
      systemSwapSpacePercentage() {
        const max = this.data.system.totalSwapSpaceSize
        if (max === 0) return 100
        const used = max - this.data.system.freeSwapSpaceSize
        let num = used / max * 100
        num = num.toFixed(2)
        return parseFloat(num)
      },

      totalSwapSpaceSize() {
        const bytes = this.data.system.totalSwapSpaceSize
        return this.humanReadableUnits(bytes)
      },

      usedSwapSpaceSize() {
        const bytes = this.data.system.totalSwapSpaceSize - this.data.system.freeSwapSpaceSize
        return this.humanReadableUnits(bytes)
      },

      systemFileDescriptorCountPercentage() {
        const max = this.data.system.maxFileDescriptorCount
        const used = this.data.system.openFileDescriptorCount
        let num = used * 1.0 / max * 100
        num = num.toFixed(2)
        return parseFloat(num)
      },
      maxFileDescriptorCount() {
        return this.data.system.maxFileDescriptorCount + ''
      },
      openFileDescriptorCount() {
        return this.data.system.openFileDescriptorCount + ''
      }
    },
    created() {
      this.loadData()
      this.setIntervalId = setInterval(() => {
        this.loadData()
      }, 5000)
    },
    methods: {
      loadData() {
        this.$http(this, {
          url: SERVICE_URLS.system.info,
          noTips: true,
          noLoadingBar: true,
          success: (data) => {
            this.data = data.body
          }
        })
      }
    }
  }
</script>